export const environment = {
  production: false,  
  DomainURL: "https://vungtrong.com/",
  DomainDestination: "https://vungtrong.com/#/",
  CMSDomainURL: "https://cms.vungtrong.com/",
  CMSDomainDestination: "https://cms.vungtrong.com/#/",
  APIURL: "https://api.cms.htxdbscl.vn/api/v1/",    
  APIRootURL: "https://api.cms.htxdbscl.vn/",        
  InitializationString: "",  
  InitializationNumber: 0, 
  DonViToChucID: 2, 
  DuKhachID: 3, 
  NguoiDanID: 4, 
  Token: "Token",
  MembershipID: "MembershipID",  
  LoginSuccess: "Đăng nhập thành công.",
  LoginNotSuccess: "Đăng nhập không thành công.",   
  RegisterSuccess: "Đăng ký thành công.",
  RegisterNotSuccess: "Đăng ký không thành công.",  
  EmailRequired: "Email is required.", 
  EmailExists: "Email đã được đăng ký.", 
  PhoneExists: "Điện thoại đã được đăng ký.", 
  EmailNotExists: "Email chưa đăng ký.", 
  MOMOExists: "MOMO đã được đăng ký.",   
  ForgotPassword: "Mật khẩu mới đã được gửi đến email của bạn.",
};

